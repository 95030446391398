<template>
  <section v-if="data" class="o-navigation-more">
    <div class="container">
      <h2 class="o-navigation-more__title">{{ data.title }}</h2>
      <div class="o-navigation-more__container">
        <Button
          v-for="element in data.links"
          :href="element.link"
          tag="a"
          modifier="quaternary"
          size="small"
        >
          {{ element.title }}
        </Button>

        <Button :href="data.all.link" tag="a" modifier="quaternary" size="small" icon-pos="right">
          {{ data.all.title }}
          <template #icon>
            <i class="icon-main-next"></i>
          </template>
        </Button>
      </div>
    </div>
  </section>

  <section v-else-if="!websiteConfig.modes.isProduction">
    <UtilsDataMissing :data="props.data" />
  </section>
</template>

<script setup lang="ts">
// Components
import UtilsDataMissing from '~/components/Utils/Data/Missing/UtilsDataMissing.vue';
import Button from '@ice-products-ui/vue-library/Button';

// Composables
import useComponentSchemaValidator from '~/composables/validators/useComponentSchemaValidator';

// Types
import type { JSONSchemaType } from 'ajv';
import type { IComponentProps } from '~/typings/types/component.types';
import type { TWebsiteConfig } from '~/typings/types/website-config.types';

defineOptions({
  name: 'ONavigationMore',
});

const props = withDefaults(defineProps<IComponentProps<TMoreDefault>>(), {
  viewName: 'ONavigationMore',
});

const websiteConfig = useState<TWebsiteConfig>('config');

type TMoreDefault = {
  title: string;
  links: {
    title: string;
    link: string;
  }[];
  all: {
    title: string;
    link: string;
  };
};

const schema: JSONSchemaType<TMoreDefault> = {
  type: 'object',
  properties: {
    title: { type: 'string' },
    links: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          title: { type: 'string' },
          link: { type: 'string' },
        },
        required: ['title', 'link'],
        additionalProperties: false,
      },
    },
    all: {
      type: 'object',
      properties: {
        title: { type: 'string' },
        link: { type: 'string' },
      },
      required: ['title', 'link'],
      additionalProperties: false,
    },
  },
  required: ['title', 'links', 'all'],
  additionalProperties: false,
};

const data = props.data.component;

useComponentSchemaValidator(schema, props);
</script>
